.videoContainer {
  width: 100%;
  max-width: 900px;
  height: 380px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .videoContainer {
    height: 200px;
  }
  
  .flexColMx24 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .flexColW400H300Mt20Ml20 {
    margin-left: 1rem;
    width: auto;
  }
}



.mobileFaqItem {
  overflow: hidden;
}

.mobileFaqButton {
  @apply cursor-pointer;
}

.mobileFaqAnswer {
  transition: max-height 0.3s ease-in-out;
}

.mobileFaqItem button[aria-expanded="true"] + .mobileFaqAnswer {
  max-height: 200px; /* Adjust as needed */
}
