.case-study {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.case-study .div-2 {
  background-color: #ffffff;
  height: 4110px;
  position: relative;
  width: 1280px;
}

.case-study .overlap {
  height: 720px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.case-study .bg {
  background-image: url(https://cdn.animaapp.com/projects/6571d427157386e1edbc1958/releases/6696b3350ae9e22eb2b1dab4/img/top-view-aerial-overloaded-toll-road-tollway-controlled-access-h.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 720px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1280px;
}

.case-study .rectangle {
  background-color: #00000052;
  height: 720px;
}

.case-study .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 48px;
  font-weight: 600;
  left: 507px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 297px;
}

.case-study .navigation-bar-instance {
  -webkit-backdrop-filter: blur(4px) brightness(100%) !important;
  backdrop-filter: blur(4px) brightness(100%) !important;
  background-color: unset !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-color: #292d3240 !important;
  box-shadow: unset !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.case-study .design-component-instance-node {
  color: #ffffff !important;
}

.case-study .overlap-group {
  height: 854px;
  left: 73px;
  position: absolute;
  top: 818px;
  width: 1135px;
}

.case-study .group {
  height: 853px;
  left: 21px;
  position: absolute;
  top: 1px;
  width: 1114px;
}

.case-study .aerial-view-shanghai {
  height: 210px;
  left: 490px;
  object-fit: cover;
  position: absolute;
  top: 29px;
  width: 135px;
}

.case-study .urban-traffic-with {
  height: 210px;
  left: 325px;
  object-fit: cover;
  position: absolute;
  top: 217px;
  width: 135px;
}

.case-study .drones-used-aerial {
  height: 240px;
  left: 655px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 459px;
}

.case-study .business-person {
  height: 240px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 457px;
  width: 459px;
}

.case-study .cinematic-style-mall {
  height: 240px;
  left: 655px;
  position: absolute;
  top: 613px;
  width: 459px;
}

.case-study .hologram-social {
  height: 312px;
  left: 490px;
  position: absolute;
  top: 270px;
  width: 624px;
}

.case-study .trip-toll-highway {
  height: 210px;
  left: 490px;
  object-fit: cover;
  position: absolute;
  top: 613px;
  width: 135px;
}

.case-study .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 460px;
}

.case-study .overlap-group-wrapper {
  height: 65px;
  position: relative;
  width: 184px;
}

.case-study .overlap-group-2 {
  height: 65px;
  position: relative;
  top: -1px;
}

.case-study .element {
  height: 17px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 184px;
}

.case-study .text-wrapper-4 {
  color: #292d32;
  font-family: "Inter", Helvetica;
  font-size: 54px;
  font-weight: 700;
  left: 4px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.case-study .paragraph {
  align-self: stretch;
  color: var(--blue-gray900);
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  position: relative;
}

.case-study .overlap-2 {
  height: 65px;
  left: 14px;
  position: absolute;
  top: 1769px;
  width: 298px;
}

.case-study .img {
  height: 24px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 266px;
}

.case-study .text-wrapper-5 {
  color: #212529;
  font-family: "Inter", Helvetica;
  font-size: 54px;
  font-weight: 700;
  left: 10px;
  letter-spacing: -1.08px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.case-study .image {
  height: 562px;
  left: 14px;
  position: absolute;
  top: 1851px;
  width: 1252px;
}

.case-study .overlap-3 {
  height: 492px;
  left: 70px;
  position: absolute;
  top: 2522px;
  width: 528px;
}

.case-study .ellipse {
  border: 1.81px dashed;
  border-color: #a7cefc;
  border-radius: 245.78px;
  height: 492px;
  left: 26px;
  position: absolute;
  top: 0;
  width: 492px;
}

.case-study .ellipse-2 {
  border: 1.81px dashed;
  border-color: #a7cefc;
  border-radius: 143.9px;
  height: 288px;
  left: 128px;
  position: absolute;
  top: 102px;
  width: 288px;
}

.case-study .group-2 {
  background-color: #ff5758;
  border-radius: 26.03px;
  height: 52px;
  left: 0;
  position: absolute;
  top: 220px;
  width: 52px;
}

.case-study .group-3 {
  background-color: #37a3ff;
  border-radius: 26.03px;
  height: 52px;
  left: 390px;
  position: absolute;
  top: 220px;
  width: 52px;
}

.case-study .group-4 {
  background-color: #ffbf60;
  border-radius: 26.03px;
  height: 52px;
  left: 246px;
  position: absolute;
  top: 364px;
  width: 52px;
}

.case-study .group-5 {
  background-color: #37a3ff;
  border-radius: 26.03px;
  height: 52px;
  left: 102px;
  position: absolute;
  top: 220px;
  width: 52px;
}

.case-study .group-6 {
  background-color: #37a3ff;
  border-radius: 26.03px;
  height: 52px;
  left: 102px;
  position: absolute;
  top: 422px;
  width: 52px;
}

.case-study .MS-SQL-vendor-wrapper {
  background-color: #37a3ff;
  border-radius: 26.03px;
  height: 52px;
  left: 419px;
  position: absolute;
  top: 53px;
  width: 52px;
}

.case-study .MS-SQL-vendor {
  height: 41px;
  left: 8px;
  position: absolute;
  top: 5px;
  width: 36px;
}

.case-study .group-7 {
  height: 52px;
  left: 476px;
  position: absolute;
  top: 298px;
  width: 52px;
}

.case-study .group-8 {
  height: 52px;
  left: 247px;
  position: absolute;
  top: 76px;
  width: 52px;
}

.case-study .group-9 {
  background-color: #ffdc4d;
  border-radius: 26.03px;
  height: 52px;
  left: 104px;
  position: absolute;
  top: 17px;
  width: 52px;
}

.case-study .logo-wrapper {
  background-color: #ffffff;
  border-radius: 9.06px;
  box-shadow: 0px 3.62px 45.28px -9.96px #00000040;
  height: 74px;
  left: 235px;
  position: absolute;
  top: 209px;
  width: 74px;
}

.case-study .logo-2 {
  height: 32px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 56px;
}

.case-study .pngimg-com-vpn {
  height: 42px;
  left: 109px;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 42px;
}

.case-study .vuesax-linear-people {
  height: 24px !important;
  left: 490px !important;
  position: absolute !important;
  top: 312px !important;
  width: 24px !important;
}

.case-study .vuesax-linear-cloud {
  height: 28px !important;
  left: 259px !important;
  position: absolute !important;
  top: 88px !important;
  width: 28px !important;
}

.case-study .new-power-BI-logo {
  height: 32px;
  left: 112px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 32px;
}

.case-study .vuesax-linear-chart {
  height: 24px !important;
  left: 404px !important;
  position: absolute !important;
  top: 232px !important;
  width: 24px !important;
}

.case-study .vuesax-linear-direct {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 231px !important;
  width: 24px !important;
}

.case-study .microsoft-excel-logo {
  height: 43px;
  left: 238px;
  position: absolute;
  top: 368px;
  width: 65px;
}

.case-study .vuesax-linear {
  height: 24px !important;
  left: 118px !important;
  position: absolute !important;
  top: 436px !important;
  width: 24px !important;
}

.case-study .overlap-wrapper {
  height: 77px;
  left: 683px;
  position: absolute;
  top: 2511px;
  width: 527px;
}

.case-study .overlap-4 {
  height: 65px;
  position: relative;
  top: 11px;
}

.case-study .element-2 {
  height: 24px;
  left: 10px;
  position: absolute;
  top: 39px;
  width: 266px;
}

.case-study .text-wrapper-6 {
  color: #212529;
  font-family: "Inter", Helvetica;
  font-size: 54px;
  font-weight: 700;
  left: 0;
  letter-spacing: -1.08px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 527px;
}

.case-study .frame-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 683px;
  position: absolute;
  top: 2620px;
  width: 481px;
}

.case-study .frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.case-study .vuesax-bold-tick {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.case-study .p {
  color: #212529;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 431px;
}

.case-study .span {
  letter-spacing: -0.06px;
}

.case-study .text-wrapper-7 {
  font-weight: 600;
  letter-spacing: -0.06px;
}

.case-study .overlap-5 {
  height: 639px;
  left: 66px;
  position: absolute;
  top: 3173px;
  width: 1148px;
}

.case-study .vuesax-bold-arrow-up-wrapper {
  background-image: url(https://cdn.animaapp.com/projects/6571d427157386e1edbc1958/releases/6696b3350ae9e22eb2b1dab4/img/image-20.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 587px;
  left: 9px;
  position: absolute;
  top: 52px;
  width: 1139px;
}

.case-study .vuesax-bold-arrow-up {
  height: 28px !important;
  left: 1080px !important;
  position: absolute !important;
  top: 94px !important;
  width: 28px !important;
}

.case-study .vector {
  height: 24px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 264px;
}

.case-study .text-wrapper-8 {
  color: #212529;
  font-family: "Inter", Helvetica;
  font-size: 54px;
  font-weight: 700;
  left: 0;
  letter-spacing: -1.08px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.case-study .footer {
  background-color: #ffffff;
  border-bottom-style: none;
  border-color: #292d3240;
  border-left-style: none;
  border-right-style: none;
  border-top-style: solid;
  border-top-width: 1px;
  height: 169px;
  left: 0;
  position: absolute;
  top: 3941px;
  width: 1280px;
}

.case-study .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 32px;
  position: relative;
  top: 32px;
  width: 1216px;
}

.case-study .frame-6 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 889px;
}

.case-study .logo-3 {
  height: 52px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.case-study .frame-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.case-study .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.case-study .text-wrapper-9 {
  color: #292d32;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.case-study .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.case-study .frame-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.case-study .text-wrapper-10 {
  color: #292d32;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.case-study .icon-instance-node {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}
